<template>
<div class="withdrawl">
    <!-- <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ $t('withdrawl.title') }}</span>
    </div> -->
    <a-icon class="back" @click="$router.go(-1)" type="left" />

    <a-tabs class="tabs" default-active-key="1" @change="onChange" :tabBarStyle="{color: '#fff'}">
    <!-- <a-icon slot="tabBarExtraContent" @click="$router.go(-1)" type="rise" /> -->

      <a-tab-pane key="1" :tab="$t('withdrawl.title')">
        <div class="mg30 list">
            <div class="flex column box" v-for="x,i in data" :key="'r'+i">
                <div class="flex title">
                <div class="amount color-primary">{{x.amount}} {{$t('common.currency')}}</div>
                <a-tag :color="statusColors[x.status]">{{$t('withdrawl.status')[x.status]}}</a-tag>
                </div>
                <div class="time color-gray">{{x.created_at}}</div>
            </div>
            
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="$t('recharge.recharge')">
        <div class="mg30 list">
            <div class="flex column box" v-for="x,i in data" :key="'r'+i">
                <div class="flex title">
                <div class="time color-gray">{{x.order_id}}</div>
                <a-tag :color="statusColors[x.status]">{{$t('recharge.status')[x.status]}}</a-tag>
                </div>
                <div class="amount color-primary">{{x.amount/1000000}} {{$t('common.currency')}}</div>
                <div class="time color-gray">{{x.created_at}}</div>
            </div>
            
        </div>
      </a-tab-pane>
      
    </a-tabs>
    <div v-if="showLoadingMore"
        :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
    >
        <a-spin v-if="loading" />
        <a-button v-else type="dashed" ghost @click="getData(true)">
            {{$t('common.more')}}
        </a-button>
    </div>

</div>
  
</template>
<script>
export default {
  name: "Withdrawl",
  data() {
    return {
    //   submitLoading:false,
      activeKey: 1,
      loading: true,
      showLoadingMore: true,
      page:1,
      pageSize: 5,
      data: [],
      statusColors: ['','green','red']
    //   amount:100,
    //   minAmount: 1,
    //   step: 1,
    //   maxAmount: 1000,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onChange(key){
        this.activeKey = key;
        this.getData(true);
        console.log(key)
    },
    getData(reset = false){
        if(reset){
            this.pageSize = 5;
            this.page = 1;
            this.data = [];
        }
        this.loading = true;
        let _url = this.activeKey==1?this.$http.api.withdrawls:this.$http.api.recharges;

        this.$http.call(_url,{
            data:{page:this.page,size:this.pageSize}
        }).then(res=>{
            // console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.page++;
                this.data = this.data.concat(resp.data);
                if(resp.data.length<this.pageSize){
                    this.showLoadingMore = false;
                }
            }else{
                this.$message.error(resp.data);
            } 
            
        },res=>{
            console.log('something goes wrong...');
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(()=>{
            
        });
    },
  },
};
</script>
<style lang="less">
.withdrawl{
    padding: 0 15px;
    width: 100%;
    // .header{
    //     position:relative;
    //     color: #fff;
    //     font-size: 16px;
    //     display:flex;
    //     align-items: center;
        .back{
            position:absolute;
            left: 15px;
            top: 35px;
            color:#fff;
        }
    //     .title{
    //         flex: 1;
    //     }
    // }
    .tabs{
        // color: #fff;
        .ant-tabs-bar{
            border-bottom: none;
        }
        .ant-tabs-nav .ant-tabs-tab-active{
            color: #fff;
            font-weight: bold;
        }
        .ant-tabs-ink-bar{
            background: #fff;
        }
    }
    .list{
        .box{
            padding: 5px 10px;
            border-radius: 5px;
            margin-bottom: 20px;
            justify-content: space-between;
            align-items: flex-start;
            .title{
                .amount{
                    font-size: 1.2rem;
                    font-weight: bold;
                }
                width: 100%;
            }
        }
    }
}

</style>
